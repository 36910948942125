import React from 'react';
import { paths } from '@src/constants/pathsEnum';
import ciCdIcon from '@images/network-software-engineering/experience_card_ciCd.svg';

export const ciCd = {
  mainSection: {
    icon: ciCdIcon,
    elements: [
      {
        type: 'text',
        items: [
          <>
            Having experience in designing and delivering both small and complex systems, we know all the dos and
            don&apos;ts of automated product deployment. CodiLime teams can build CI/CD pipelines from the ground up or
            optimize existing ones.
          </>,
        ],
      },
    ],
  },
  howWeHelpSection: {
    elements: [
      {
        type: 'list',
        items: [
          {
            item: (
              <>
                Build full infrastructure for CI/CD platforms from scratch or utilize any of the SaaS CI/CD solutions
                available on the market
              </>
            ),
          },
          {
            item: (
              <>
                Build CI/CD and optimize pipelines that support different configurations using on-prem, virtualized or
                cloud environments according to the client’s functional and non-functional requirements
              </>
            ),
          },
          {
            item: <>Build comprehensive CI/CD monitoring dashboards applying best project observability patterns</>,
          },
          {
            item: <>Provide maintenance and hosting services for the most demanding CI/CD systems</>,
          },
          {
            item: (
              <>Build cloud deployment wizards allowing for one-click deployment of complex software in the cloud</>
            ),
          },
        ],
      },
    ],

    relatedResources: [
      {
        type: 'Case study',
        items: [
          {
            title: 'Building CI/CD and testing for a full-stack monitoring and alerting service',
            link: '/case-studies/',
          },
          {
            title: 'Designing an open-source CI tool to simplify GitOps with Spinnaker',
            link: '/case-study/designing-open-source-ci-tool-to-simplify-gitops-with-spinnaker/',
          },
        ],
      },
      {
        type: 'White paper',
        items: [
          {
            title: 'CI/CD in six weeks: setup and optimization',
            link: paths.RESOURCES.CI_CD_PIPELINE,
          },
        ],
      },
    ],
  },
};
